"use client";
import {
  cloneElement,
  forwardRef,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useState,
} from "react";
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import ChevronIcon from "@traveloka/icon-kit-web/react/IcSystemChevronDown";

import { isReactText } from "../../utils";
import { useControlled, useInitialRenderRef } from "../hooks";
import Text from "../Text";
import Token from "../Token";
import View from "../View";

export type CollapsibleRef = {
  toggle(): void;
  setVisible(visible: boolean): void;
};

export type CollapsibleProps = {
  children?: ReactNode;
  /**
   * Custom style applied to the parent container of the content
   */
  contentContainerStyle?: StyleProp<ViewStyle>;
  /**
   * Custom style applied to the content
   */
  contentStyle?: StyleProp<ViewStyle>;
  /**
   * Initial collapsible state, provide if needed to control initial
   * state for uncontrolled collapsible
   * @default false
   */
  defaultVisible?: boolean;
  /**
   * If enabled, will remove shadow in collapsible header when opened
   */
  disableShadow?: boolean;
  /**
   * Node to be displayed in the toggle.
   * ReactText will be wrapped in Text
   */
  header?: ReactNode;
  /**
   * Start icon node (left for LTR orientation)
   */
  icon?: ReactElement;
  /**
   * Component has icon or not
   */
  hasIcon?: boolean;
  /**
   * Chevron icon position (left or right)
   */
  chevronPosition?: "left" | "right";
  /**
   * Controlled collapsible state
   */
  visible?: boolean;
  /**
   * Controlled collapsible toggler function
   */
  onPress?(): void;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Custom style applied to the header element
   */
  headerStyle?: StyleProp<ViewStyle>;
  /**
   * Custom style applied to the chevron icon
   */
  chevronStyle?: StyleProp<ViewStyle>;
  /**
   * Test ID
   */
  testID?: string;
  /**
   * To support SEO or not
   */
  isNotSupportSeo?: boolean;
  /**
   * offset for the collapsible content
   * @default 0
   */
  offset?: number;
};

export default forwardRef<CollapsibleRef, CollapsibleProps>(
  function Collapsible(props, ref) {
    const {
      children,
      contentContainerStyle,
      contentStyle: contentStyleProp,
      defaultVisible,
      disableShadow,
      header,
      icon = <Icon />,
      hasIcon = true,
      chevronPosition = "right",
      onPress,
      style,
      headerStyle,
      chevronStyle,
      visible: visibleProp,
      testID,
      isNotSupportSeo = false,
      offset = 0,
    } = props;

    const [visible, setVisible] = useControlled({
      name: "Collapsible",
      prop: "visible",
      value: visibleProp,
      defaultValue: defaultVisible,
    });

    const reduceMotion = useInitialRenderRef().current && visible;
    const [height, setHeight] = useState(offset);

    function handleLayout(e: LayoutChangeEvent) {
      setHeight(e.nativeEvent.layout.height);
    }

    function handlePress() {
      setVisible(!visible);

      if (typeof onPress === "function") onPress();
    }

    useImperativeHandle(
      ref,
      () => ({
        toggle() {
          setVisible(!visible);
        },
        setVisible: setVisible,
      }),
      [setVisible, visible]
    );

    const containerStyle: StyleProp<ViewStyle> = [
      styles.container,
      disableShadow && styles.noShadow,
      {
        height: reduceMotion ? undefined : visible ? height : offset,
        overflow: visible ? "visible" : "hidden",
      },
      contentContainerStyle,
    ];
    const iconStyle = [
      styles.icon,
      visible && styles.iconVisible,
      chevronStyle,
    ];
    const contentStyle = [
      styles.content,
      visible && styles.contentVisible,
      contentStyleProp,
    ];

    return (
      <View style={[styles.root, style]} testID={testID}>
        {header !== undefined && (
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={handlePress}
            style={[styles.toggle, headerStyle]}
          >
            <View style={styles.header} align="center" row spacing="xs">
              {hasIcon && chevronPosition === "left" && (
                <View style={iconStyle}>
                  {icon && cloneElement(icon, { height: 24, width: 24 })}
                </View>
              )}

              {isReactText(header) && <Text>{header}</Text>}
              {!isReactText(header) && header}

              {hasIcon && chevronPosition === "right" && (
                <View style={iconStyle}>
                  {icon && cloneElement(icon, { height: 24, width: 24 })}
                </View>
              )}
            </View>
          </TouchableOpacity>
        )}
        <View style={containerStyle}>
          <View onLayout={handleLayout} style={contentStyle}>
            {isNotSupportSeo ? visible && children : children}
          </View>
        </View>
      </View>
    );
  }
);

function Icon() {
  return <ChevronIcon color={Token.color.bluePrimary} height={24} width={24} />;
}

const styles = StyleSheet.create({
  root: {
    borderRadius: Token.borderRadius.normal,
    backgroundColor: Token.color.lightPrimary,

    // @ts-ignore to remove blue outline when collapsible is clicked
    outline: "none",
  },
  toggle: {
    padding: Token.spacing.s,
  },
  header: {
    flex: 1,
  },
  icon: {
    alignSelf: "center",
    marginStart: "auto",
    transform: [{ rotate: `0deg` }],
    //@ts-ignore
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "transform",
  },
  iconVisible: {
    transform: [{ rotate: "-180deg" }],
  },
  container: {
    overflow: undefined,
    //@ts-ignore
    boxShadow: `0 1px 1px 0 inset ${Token.color.lightNeutral}`,
    transitionProperty: "height",
    transitionDuration: `${Token.timing.instant}ms`,
  },
  content: {
    opacity: 0,
    padding: Token.spacing.s,
    transform: [{ translateY: Token.spacing.s }],
    //@ts-ignore
    transitionDuration: `${Token.timing.instant}ms, ${Token.timing.slow}ms`,
    transitionProperty: "transform, opacity",
  },
  contentVisible: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
  noShadow: {
    //@ts-ignore
    boxShadow: "none",
  },
});
