import { ColorValue } from "react-native";

const color = {
  darkPrimary: "#03121A",
  darkStain: "#1C2930",
  darkNeutral: "#354148",
  darkSecondary: "#687176",

  lightPrimary: "#FFFFFF",
  lightStain: "#F7F9FA",
  lightNeutral: "#F2F3F3",
  lightSecondary: "#CDD0D1",

  blueLight: "#ECF8FF",
  bluePrimary: "#0194F3",
  blueSecondary: "#007CE8",
  blueDark: "#0264C8",

  greenLight: "#E8FEF5",
  greenPrimary: "#0BC175",
  greenSecondary: "#05A569",
  greenDark: "#00875A",

  yellowLight: "#FFFAD9",
  yellowPrimary: "#FFDC00",
  yellowSecondary: "#D99800",
  yellowDark: "#B15400",

  orangeLight: "#FFF4EF",
  orangePrimary: "#FF5E1F",
  orangeSecondary: "#DF440F",
  orangeDark: "#BF1D00",

  redLight: "#FCE3E4",
  redPrimary: "#F4555A",
  redSecondary: "#EC3A3E",
  redDark: "#E7090E",

  borderSubtle: "#F2F3F3",
  borderDivide: "#CDD0D1",

  godwitBlue: "#1BA0E2", // Traveloka
  highAltitude: "#30C5F7", // Flight
  goodNight: "#235D9F", // Hotel
  speedLine: "#FCA000", // Train
  ripeCarrot: "#F86F09", // Eats
  businessSuit: "#073E68", // Payment
  coralReef: "#FF6D6A", // Xperience
  terminalLounge: "#6DD3CE", // Airport Transport
  fuelTank: "#087E8B", // Car Rental
  fieldTrip: "#20BF55", // Bus
  signalPulse: "#F0298A", // Data Package
  fuchsiaFusion: "#931682", // Trip Packages
  shieldProtector: "#3B44AB", // Insurance
  wrapPaper: "#FF474E", // Gift
  goldCoin: "#E1CB37", // Points
  greenCsf: "#0F4C56", // Caturnusa Sejahtera Finance

  // Bloom UI
  bmBadgeInkGreen: "#0A5C2C",
  bmBadgeBgGreen: "#DDFEB4",
};

export type Color = keyof typeof color;

const spacing = {
  xxs: 4,
  xs: 8,
  s: 12,
  m: 16,
  ml: 20,
  l: 24,
  xl: 32,
  xxl: 40,
  xxxl: 48,
  xxxxl: 56,
  xxxxxl: 64,
};

export type Spacing = keyof typeof spacing;

const timing = {
  instant: 200,
  normal: 300,
  slow: 500,
};

export type Timing = keyof typeof timing;

const borderWidth = {
  thin: 0.5,
  thick: 1,
  bold: 2,
};

export type BorderWidth = keyof typeof borderWidth;

const borderRadius = {
  normal: 6,
  rounded: 1000,
};

export type BorderRadius = keyof typeof borderRadius;

function makeOpacityGetter(opacity: number) {
  function getOpacity(): number;
  function getOpacity(color: string): ColorValue;
  function getOpacity(color?: string): number | ColorValue {
    if (!color) return opacity;

    const opacityHexNum = Math.round(opacity * 255);
    return color + opacityHexNum.toString(16);
  }
  return getOpacity;
}

const opacity = {
  opaque: makeOpacityGetter(0.8),
  obscure: makeOpacityGetter(0.65),
  translucent: makeOpacityGetter(0.5),
  washedOut: makeOpacityGetter(0.4),
  seeThrough: makeOpacityGetter(0.2),
  clear: makeOpacityGetter(0.15),
};

export type Opacity = keyof typeof opacity;

function createTypography(
  fontSize: number,
  lineHeight: number,
  fontWeight: "400" | "600" | "900"
) {
  return {
    fontFamily:
      "Godwit, System, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
    fontSize,
    fontWeight,
    lineHeight,
    fontVariantLigatures: "none",
  };
}

const typography = {
  hero: createTypography(48, 56, "600"),
  display: createTypography(32, 40, "600"),
  headline: createTypography(24, 28, "600"),
  title1: createTypography(20, 24, "600"),
  title2: createTypography(16, 20, "600"),
  title3: createTypography(14, 16, "600"),
  uiLarge: createTypography(20, 28, "400"),
  uiBaseline: createTypography(16, 24, "400"),
  uiSmall: createTypography(14, 20, "400"),
  uiTiny: createTypography(12, 16, "400"),
  uiLargeBold: createTypography(20, 28, "600"),
  uiBaselineBold: createTypography(16, 24, "600"),
  uiSmallBold: createTypography(14, 20, "600"),
  uiTinyBold: createTypography(12, 16, "600"),
  captionTiny: createTypography(12, 12, "400"),
  captionMicro: createTypography(11, 11, "400"),
  buttonLarge: createTypography(20, 32, "600"),
  buttonMedium: createTypography(16, 24, "600"),
  buttonSmall: createTypography(14, 16, "600"),
};

export type Typography = keyof typeof typography;

function createShadow(y: number, blur: number, opacity: number) {
  // color value equivalent to color.darkPrimary
  return `0 ${y}px ${blur}px rgba(3, 18, 26, ${opacity})`;
}

const shadow = {
  flat: "",
  container: createShadow(1, 2, opacity.seeThrough()),
  raised: createShadow(2, 5, opacity.clear()),
  float: createShadow(4, 10, opacity.clear()),
  hover: createShadow(8, 16, opacity.seeThrough()),
};

export type Shadow = keyof typeof shadow;

// TODO: fill in correct value
const zIndex = {
  appBar: 1000,
  modal: 1100,
  snackbar: 1200,
  tooltip: 1300,
};
export type ZIndex = keyof typeof zIndex;

function setZIndex(number: number) {
  return { zIndex: number };
}

const token = {
  color,
  spacing,
  timing,
  borderWidth,
  borderRadius,
  opacity,
  typography,
  shadow,
  zIndex,
  setZIndex,
};

export default token;
