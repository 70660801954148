import { AccountStatus } from "./types";

export function parseStatus(status: AccountStatus | null) {
  if (!status) {
    return {
      isSuspended: false,
      isContractExpired: false,
      isActive: false,
      isRegistered: false,
    };
  }

  return {
    isSuspended: status === "SUSPENDED",
    isContractExpired: status === "CONTRACT_EXPIRED",
    isActive: status === "ACTIVE",
    isRegistered: status === "REGISTERED",
  };
}

/**
 *
 * @param status
 * @returns
 *
 * Return isCorporateActive. `false` if status is `SUSPENDED` or `CONTRACT_EXPIRED`, otherwise `true`.
 */
export function parseStatusToCorporateAvailability(
  status: AccountStatus | null
) {
  if (!status) {
    return false;
  }

  return status === "ACTIVE";
}
