import htmr from "htmr";
import { FC, ReactNode } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import AlertIcon from "@traveloka/icon-kit-web/react/IcSystemStatusFailFill16";
import InfoIcon from "@traveloka/icon-kit-web/react/IcSystemStatusInfoFill16";
import PositiveIcon from "@traveloka/icon-kit-web/react/IcSystemStatusOkDoneFill16";
import WarningIcon from "@traveloka/icon-kit-web/react/IcSystemStatusWarningFill16";

import { IconKitProps } from "../../types";
import Button, { ButtonProps } from "../Button";
import Text, { TextProps } from "../Text";
import Token from "../Token";
import View from "../View";

type Variant = "alert" | "info" | "positive" | "warning";

export type InfoBoxProps = {
  /**
   *
   */
  action?: string;
  Action?: ReactNode;
  children?: ReactNode;
  /**
   * Remove border on the side of the component
   */
  float?: boolean;
  /**
   *
   */
  onPress?(): void;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  testID?: string;
  /**
   * Message displayed
   */
  // message: string;
  /**
   *
   */
  title?: string;
  /**
   * Defines look and feel of the component
   * @default 'info'
   */
  variant?: Variant;
};

const buttonVariantMap: Record<Variant, ButtonProps["variant"]> = {
  alert: "text-black",
  info: "text-primary",
  positive: "text-black",
  warning: "text-black",
};

const textInkMap: Record<Variant, TextProps["ink"]> = {
  alert: "alert",
  info: "highlight",
  positive: "positive",
  warning: "warning",
};

const iconMap: Record<Variant, FC<IconKitProps>> = {
  alert: () => (
    <AlertIcon color={Token.color.redPrimary} width={16} height={16} />
  ),
  info: () => (
    <InfoIcon color={Token.color.bluePrimary} width={16} height={16} />
  ),
  positive: () => (
    <PositiveIcon color={Token.color.greenPrimary} width={16} height={16} />
  ),
  warning: () => (
    <WarningIcon color={Token.color.yellowPrimary} width={16} height={16} />
  ),
};

export default function InfoBox(props: InfoBoxProps) {
  const {
    action,
    Action,
    children,
    float,
    onPress,
    style,
    testID,
    title,
    variant = "info",
  } = props;

  const Icon = iconMap[variant];

  return (
    <View
      nativeID={testID}
      align="start"
      spacing="xxs"
      style={[float ? styles.float : styles.stretch, styles[variant], style]}
    >
      {typeof title === "string" && (
        <View align="center" row spacing="xs">
          <Icon />
          <Text style={styles.title} variant="ui-tiny-bold">
            {htmr(title)}
          </Text>
        </View>
      )}
      {children && (
        <Text ink={textInkMap[variant]}>
          {typeof children === "string" ? htmr(children) : children}
        </Text>
      )}
      {typeof action === "string" && (
        <Button
          onPress={onPress}
          size="small"
          text={action}
          variant={buttonVariantMap[variant]}
        />
      )}
      {Action}
    </View>
  );
}

const styles = StyleSheet.create({
  stretch: {
    borderStartWidth: 3,
    padding: Token.spacing.s,
    paddingStart: Token.spacing.s - 3,
  },
  float: {
    borderWidth: Token.borderWidth.thin,
    borderRadius: Token.borderRadius.normal,
    padding: Token.spacing.s - Token.borderWidth.thin,
  },
  icon: {
    marginTop: Token.spacing.xxs,
  },
  title: {
    opacity: Token.opacity.obscure(),
  },

  // Variant
  alert: {
    backgroundColor: Token.color.redLight,
    borderColor: Token.color.redSecondary,
  },
  positive: {
    backgroundColor: Token.color.greenLight,
    borderColor: Token.color.greenSecondary,
  },
  warning: {
    backgroundColor: Token.color.yellowLight,
    borderColor: Token.color.yellowSecondary,
  },
  info: {
    backgroundColor: Token.color.blueLight,
    borderColor: Token.color.blueSecondary,
  },
});
