import IcSystemChevronLeft from "@traveloka/icon-kit-web/react/IcSystemChevronLeft";
import IcSystemChevronRight from "@traveloka/icon-kit-web/react/IcSystemChevronRight";
import { PropsWithChildren, ReactElement } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Text from "../Text";
import Token from "../Token";
import View from "../View";

type Props = {
  page?: number;
  totalPage?: number;
  nextPageTrigger?: string | ReactElement;
  previousPageTrigger?: string | ReactElement;
  setPage?: (page: number) => void;
};

export default function NumberedPagination({
  page = 1,
  totalPage = 3,
  nextPageTrigger,
  previousPageTrigger,
  setPage,
}: Props) {
  const isPreviousTriggerDisabled = page === 1;
  const isNextTriggerDisabled = page === totalPage;
  const totalPageIsLessThanThree = totalPage < 3;

  function renderPageIndicatorNumber(page: number, index: number) {
    if (totalPageIsLessThanThree || page === 1) {
      return index + 1;
    }
    if (page === totalPage) {
      return totalPage - 2 + index;
    }
    return page - 1 + index;
  }

  return (
    <View spacing="m" row align="center">
      <TouchableOpacity
        onPress={() => setPage?.(page - 1)}
        disabled={isPreviousTriggerDisabled}
      >
        <PreviousTrigger disabled={isPreviousTriggerDisabled}>
          {previousPageTrigger}
        </PreviousTrigger>
      </TouchableOpacity>
      {[
        ...Array.from({ length: totalPageIsLessThanThree ? totalPage : 3 }),
      ].map((_, idx) => {
        const indicator = renderPageIndicatorNumber(page, idx);
        return (
          <TouchableOpacity onPress={() => setPage?.(indicator)} key={idx}>
            <View
              style={[
                styles.paginationTriggerIndicator,
                indicator === page && styles.activeNumberedPageIndicator,
              ]}
            >
              <Text
                ink={indicator === page ? "white-primary" : "black-primary"}
              >
                {indicator}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
      <TouchableOpacity
        onPress={() => setPage?.(page + 1)}
        disabled={isNextTriggerDisabled}
      >
        <NextTrigger disabled={isNextTriggerDisabled}>
          {nextPageTrigger}
        </NextTrigger>
      </TouchableOpacity>
    </View>
  );
}

type TriggerProps = PropsWithChildren<{
  disabled: boolean;
}>;

function NextTrigger({ children, disabled }: TriggerProps) {
  if (children === undefined) {
    return (
      <View
        style={[
          styles.paginationTriggerIndicator,
          styles.chevronTrigger,
          disabled ? styles.disabledChevronTrigger : {},
        ]}
      >
        <IcSystemChevronRight width={16} height={16} />
      </View>
    );
  }

  if (typeof children === "string") {
    return (
      <Text ink={disabled ? "black-muted" : "black-primary"}>{children}</Text>
    );
  }

  return children;
}

function PreviousTrigger({ children, disabled }: TriggerProps) {
  if (children === undefined) {
    return (
      <View
        style={[
          styles.paginationTriggerIndicator,
          styles.chevronTrigger,
          disabled ? styles.disabledChevronTrigger : {},
        ]}
      >
        <IcSystemChevronLeft width={16} height={16} />
      </View>
    );
  }

  if (typeof children === "string") {
    return (
      <Text ink={disabled ? "black-muted" : "black-primary"}>{children}</Text>
    );
  }

  return children;
}

const styles = StyleSheet.create({
  chevronTrigger: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.xs,
  },
  disabledChevronTrigger: {
    backgroundColor: Token.color.lightSecondary,
  },
  paginationTriggerIndicator: {
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    borderRadius: Token.borderRadius.normal,
    backgroundColor: Token.color.lightPrimary,
  },
  activeNumberedPageIndicator: {
    color: Token.color.lightPrimary,
    backgroundColor: Token.color.bluePrimary,
  },
  disabledIndicator: {
    color: Token.color.lightSecondary,
  },
});
