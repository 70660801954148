import { useMemo } from "react";

import { User, decodeBase64, encodeBase64, useAuth } from "@ctv/core";

// fpc: First PIC Coachmark
export const firstPicCoachmarkKey = "fpc";

// wnpic: Welcome new pic
export const welcomeIntroKey = "wnpic";

// cim: CompanyIntroModal
export const companyIntroKey = "cim";

// cpic: companyPermissionsIntroCoachmark
export const companyPermissionIntroKey = "cpic";

// psic: peopleStructureIntroCoachmark
export const peopleStructureIntroKey = "psic";

// nfib: newFeatureInsuranceBanner
export const newFeatureInsuranceBannerKey = "nfib";

export function useLocalStorageUserLevel(key: string) {
  const { user } = useAuth();

  const data = useMemo(() => {
    return {
      get: () => get(key, user),
      set: () => set(key, user),
    };
  }, [user, key]);

  return data;
}
export function get(key: string, user?: User) {
  if (!user) {
    return false;
  }

  // Intentional using window.localStorage because the `get` function is for object (using JSON.parse)
  const val = window.localStorage.getItem(makeKey(key, user)) as string | null;
  return (val ? decodeBase64(val) : null) === user.email;
}

export function set(key: string, user?: User) {
  if (!user) {
    return;
  }

  window.localStorage.setItem(makeKey(key, user), encodeBase64(user.email));
}

function makeKey(key: string, user: User) {
  return `${key}-${encodeBase64(user.email)}`;
}
