"use client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useLocale } from "@ctv/shared-core/src";

import { isOffline } from "../dev/constants";
import { fetchFeatureControl } from "./server-action";
import { Feature, FeatureControl } from "./types";

const FeatureControlContext = createContext<FeatureControl>({});

export function FeatureControlProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [featureControl, setFeatureControl] = useState<FeatureControl>(
    createProxy({})
  );
  const locale = useLocale();

  useEffect(() => {
    if (isOffline === true) {
      setFeatureControl(createProxy({}));
      return;
    }

    fetchFeatureControl(locale).then((response) => {
      const fc: FeatureControl = {};

      if (!response) {
        return;
      }

      Object.keys(response).forEach((key) => {
        const { enabled, properties } = response[key];

        fc[key] = {
          enabled: String(enabled) === "true",
          properties,
        };
      });

      Array.from(
        { length: window.localStorage.length },
        (_, index) => window.localStorage.key(index) as string
      ).forEach((key) => {
        if (key.startsWith("fc-")) {
          const enabled = window.localStorage[key] === "true";
          const fcKey = key.replace(/^fc-/, "");

          fc[fcKey] = fc[fcKey] ?? {};
          fc[fcKey].enabled = enabled;
        }
      });

      setFeatureControl(createProxy(fc));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  return (
    <FeatureControlContext.Provider value={featureControl}>
      {children}
    </FeatureControlContext.Provider>
  );
}

export function useGlobalFeatureControl() {
  const value = useContext(FeatureControlContext);

  if (value === undefined) {
    throw new Error("`FeatureControlContext` could not find any provider");
  }

  return value;
}

export function useFeatureControl<T = unknown>(featureKey: string) {
  return useGlobalFeatureControl()[featureKey] as Feature<T>;
}

// Feature Control
const defaultFc = { enabled: null, properties: {} };
export function createProxy(value: FeatureControl) {
  return new Proxy(value, {
    get(obj, key: string) {
      return obj[key] ?? defaultFc;
    },
  });
}
